.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  float: left;
}

.bot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  float: left;
}

.wrapperForProcessVariableInputField {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.wrapperForProcessVariableInputField .ant-input {
  margin-left: 3px;
  margin-right: 3px;
}
