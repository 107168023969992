.submit {
  display: flex;
  justify-content: flex-end;
  margin-right: 150px;
}

.wrapperNavFinancial {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}
.wrapperNavMutual {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}

.wrapperNavIpo {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}

.headingNav {
  margin-left: 40px;
  margin-bottom: 50px;
}

.leftLongIcon {
  cursor: pointer;
}

.rows {
  display: flex;
  flex-direction: column;
}

.firstRow {
  display: flex;
  justify-content: space-around;
}

.secondRow {
  margin-left: 55px;
}

.submitButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}
