.simpleTable {
    max-height: 500px;
    overflow-y: scroll;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.75rem;
    font-family: Inter, sans-serif;
    max-width:80vw
}

.ant-table .ant-table-header {
    background: #f0f2f5;
}

.ant-table-thead > tr > th {
    background: #f0f2f5;
    border-bottom: 1px solid black;
    padding-inline: 0.625rem;
    padding-block: 1rem;
}

.ant-table-body::-webkit-scrollbar {
    display: none;
}

.ant-table-body::-webkit-scrollbar-track {
    background: #CFD8DC;
}

.ant-table-body::-webkit-scrollbar-thumb {
    background: #90A4AE;
    border-radius: 6px;
}

.ant-table-tbody > tr > td {
    background: #f0f2f5;
    /*padding:0;*/
    padding-top: 10px;
    padding-bottom: 0;
    border: none;
    font-weight: 700;
    color: #2D3648;
}

.ant-table-column-sort {
    background: #f0f2f5;
    border: none;
}

.ant-input {
    max-width: 15rem;
}

.actionColumn {
    display: flex;
    justify-content: space-evenly;
}
