.space {
    margin: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.flex {
    display: flex;
    gap: 2rem;
}

.side-2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
