*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.feature-flowvity-cards{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    /*justify-content: center;*/
    /*align-items: center;*/
    /*height: 100%;*/
    /*row-gap: 100px;*/
}