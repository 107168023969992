.wrapperForCustomInputField {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.ant-input {
  max-width: 14rem;
}
