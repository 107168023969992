* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.containerDiv {

  padding-left: 60px;
  padding-right: 60px;

}

.head {
  text-align: left;
}
.head h1 {
  font: "Roboto";
  font-weight: 600;
  font-size: 26px;
  line-height: 24.5px;
  letter-spacing: 0.2%;
  margin-top: 31px;
  margin-bottom: 36px;
}

.wrapperFund {
  display: flex;
  flex-direction: row;
}

.wrapperFund .ant-col.ant-form-item-label {
  margin-left: 0px;
}

.wrapperFund .ant-col.ant-form-item-label {
  min-width: 0px;
}

.ant-btn.ant-btn-default {
  border-radius: 7px;
  color: white;

  font-size: 14px;

}

.wrapperFund .ant-btn.ant-btn-default {
  margin-left: 16px;
}

.wrapperFund .ant-input {
  border-radius: 7px;
  width: 462px;
}

.input-container {
  position: relative;
}

.icon-container {
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translateY(-50%);
}

.input-container .ant-input {
  text-indent: 20px;
}

.histNavTable {
  margin-bottom: 40px;
}


.foot .ant-form.ant-form-horizontal {
  display: flex;
  justify-content: space-between;
}

.navInput {
  border-radius: 8px;
  width: 68px;
  height: 30px;
  text-align: center;
}

.dateClass {
  border-radius: 8px !important;
}

.foot .ant-col.ant-form-item-label {
  margin-left: 0px !important;
}

