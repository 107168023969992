.container {
    text-align: center;
    width: 100%;
  }
  
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    font-size: calc(10px + 2vmin);
    background-color: white;
    z-index: 1;
  }
  .searchBarWrapper {
    width: 100%;
    /* min-width: 1000px; */
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    align-items: flex-end;
    justify-content: space-around;
  }
  .searchBarWrapper MaterialTablePagination {
    align-items: flex-end;
  }
  .tableWrapper{
     margin:10px;
     min-width:800px;
  }