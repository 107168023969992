.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
}

.Table__pagination {
    display: flex;
    justify-content: center;
    padding: 20px 10px;
}

.Table__pageButton {
    font-size: 15px;
    border: none;
    padding: 5px 7px;
    margin: 0 4px;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
}

.Table__pageButton--active {
    border: 1px solid rgb(95, 90, 90);
    border-radius: 5px;
    color: #244E9A;
    outline: none;
}
