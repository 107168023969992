.list-container {
    display: flex;
    flex-direction: column !important;
    background-color: white;
    border: 0.1rem solid #e7e4e4;;
    padding: 0.2rem 0.5rem;;
    margin-bottom: 1.4rem;
}

.editable-item {
    display: grid;
    grid-template-columns: 95% 5%;
    gap: 0.2rem;
    background-color: white;
    padding: 5px 10px;
}


.item-border {
    border: 1px solid #f3f0f0;
}

.delete-container {
    display: grid;
    place-items: end;
    cursor: pointer;
}

.value-container {
    margin: 0;
    padding: 0.1rem;
}

.input-p {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 0.9rem;
}
