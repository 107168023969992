.nepali-datepicker {
  border: 1px solid #c8cec8 !important;
  margin-top: -30px;
  border-radius: 5px !important;
  width: 300px !important;
  background-color: #fff !important;
}

.nepali-datepicker input {
  border: none !important;
  outline: none !important;
  width: 100% !important;
  background: transparent !important;
}

.nepali-datepicker-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dateDiv {
  display: flex;
  flex-direction: row;
  /* align-items: center; */

  border: 1px solid #333;
  border-radius: 4px;
}

.dateDiv .input-calendar,
.dateDiv input {
  z-index: 10;
  background: transparent;
  border: none;
  outline: none;
}

.calendar {
  margin-left: -40px;
  z-index: 7;
}
.wrapperForCalendarAndNdp {
  display: flex;
  flex-direction: row;
}

.wrapperForInputDiv {
  display: none;
  display: flex;
  flex-direction: row;
}

.nepali-datepicker,
.englishInputClass,
.inputClass {
  box-sizing: border-box;

  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;

  padding: 4px 11px 4px;

  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: border 0.3s, box-shadow 0.3s;
}

.englishInputClass {
  margin-left: 90px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col {
  width: calc(50% - 10px);
}

.wrapperNav {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wrapperNav h3 {
  margin-left: 40px;
}

.wrapperNav button {
  padding: 5px;
}

.rightWrapperNav button {
  margin: 8px;
}

.bs-date-picker .ant-form-item {
  height: 0;
  margin: 0;
}

.row .ant-select-selector {
  border-radius: 6px !important;
}

.row .ant-picker {
  border-radius: 6px;
}
.row .ant-input {
  border-radius: 6px;
}

.inputClass {
  border-radius: 6px;
}
.englishInputClass {
  border-radius: 6px;
}

.ant-col.ant-form-item-label {
  min-width: 170px;
  margin-left: 40px;
  text-align: left;
}

.trasparentButton {
  background: transparent;
  border: 0;
  color: #3f51b5;
}

.circlePlusIcon {
  margin-left: 5px;
}

.saveButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
}

#resetDateButton {
  /* border: none; */
  margin-right: -20px;
}

.reset-date-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
}
