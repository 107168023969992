.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.ant-menu-inline .ant-menu-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    /*padding-block: 8 px;*/
    /*height: fit-content;*/
}

.ant-layout-sider-children {
    height: 100vh !important;
}

/*.ant-menu-inline-collapsed .ant-menu-item {*/
/*    padding-block: 8px;*/
/*    height: fit-content;*/
/*}*/

.back-button {
    /*color: white;*/
    font-size: 28px !important;
    /*margin-inline: 10px;*/
}

.back-button-container {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    height:64px;
}